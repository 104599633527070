<script lang="ts" setup>
enum Size {
  XSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}

enum Style {
  Primary = 'primary',
  PrimaryInvert = 'primary-invert',
  Secondary = 'secondary',
  Ghost = 'ghost',
}

enum IconPos {
  Left = 'left',
  Right = 'right',
  Without = 'without',
  Only = 'only',
}

const props = defineProps({
  linkTo: {
    type: String,
    default: null,
  },
  size: {
    type: String,
    default: Size.Medium,
    validator: (value: string) => Object.values(Size).includes(value as Size),
  },
  styleBtn: {
    type: String,
    default: Style.Primary,
    validator: (value: string) => Object.values(Style).includes(value as Style),
  },
  iconType: {
    type: String,
    default: IconPos.Without,
    validator: (value: string) => Object.values(IconPos).includes(value as IconPos),
  },
  iconName: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const styleBtn = computed(() => {
  switch (props.styleBtn) {
    case Style.Primary:
      return 'bg-surface-brand text-text-white hover:bg-surface-brand-hover'

    case Style.PrimaryInvert:
      return 'bg-surface-block text-text-brand'

    case Style.Secondary:
      return 'text-text-secondary border-border-secondary border hover:bg-surface-brand-low active:border-border-brand'

    case Style.Ghost:
      return 'bg-surface-brand text-text-white'

    default:
      return ''
  }
})

const btnPadding = computed(() => {
  switch (props.size) {
    case Size.XSmall:
      return 'py-1.5 px-2.5 rounded-lg text-base focus-visible:outline-offset-[3px]'

    case Size.Small:
      return 'py-2.5 px-4 rounded-[10px] text-base focus-visible:outline-offset-[3px]'

    case Size.Medium:
      return 'py-3 px-6 rounded-xl text-lg focus-visible:outline-offset-[3px]'

    case Size.Large:
      return `py-4 ${props.iconType === IconPos.Only ? 'px-4' : 'px-6'} rounded-2xl text-lg focus-visible:outline-offset-1`

    default:
      return ''
  }
})

const iconSize = computed(() => {
  switch (props.size) {
    case Size.Small:
    case Size.Medium:
    case Size.Large:
      return '1.5rem'

    case Size.XSmall:
      return '1.25rem'

    default:
      return undefined
  }
})

const iconPos = computed(() => {
  switch (props.iconType) {
    case IconPos.Left:
      return 'mr-2'

    case IconPos.Right:
      return ' ml-2'

    case IconPos.Without:
      return ''

    case IconPos.Only:
      return ''

    default:
      return ''
  }
})

const emit = defineEmits<{
  (event: 'click'): void
}>()
</script>

<template>
  <NuxtLink
    v-if="linkTo"
    :to="linkTo"
    :class="[
      'flex-inline items-center outline-none transition-colors duration-300',
      styleBtn,
      btnPadding,
      'focus-visible:(outline-2 outline-border-brand)',
    ]"
    :disabled="disabled"
  >
    <slot />
  </NuxtLink>
  <button
    v-else
    :class="[
      'flex-inline items-center outline-none transition-colors duration-300',
      styleBtn,
      btnPadding,
      'focus-visible:(outline-2 outline-border-brand)',
    ]"
    :disabled="disabled"
    @click="emit('click')"
  >
    <slot />
    <Icon v-if="iconName" :name="iconName" :size="iconSize" :class="iconPos" class="flex" />
  </button>
</template>
